.profile-body {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 0.8rem;
}

.profile-button {
  border: none;
  border-radius: 0.5rem;
  font-family: "Pretendard";
  font-weight: 500;

  padding: 0.5rem 0.85rem;

  display: flex;
  gap: 0.5rem;
  background-color: #ededed;
}

.profile-button:hover {
  background-color: #dcdcdc;
}

.profile-button img {
  height: 1rem;
  margin-top: 1px;
}
