p {
  margin: 0;
}

.profile-info {
  font-size: 1.4rem;
  text-align: left;
  width: 0 auto;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    padding-top: 1.2rem;
  }
}

.profile-name {
  font-weight: 500;
}

.profile-description {
  margin-top: 1rem;
  color: #555;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.profile-logo {
  height: 20px;
  fill: #000;

  @media (max-width: 768px) {
    height: 1rem;
  }
}

.profile-header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: 0 auto;
    min-width: 280px;
  }
}

.external-link {
  display: flex;
  gap: 0.6rem;
}

.external-link a {
  display: flex;
  align-items: flex-end;
  padding-bottom: 3px;
}

#br-only-desktop {
  @media (max-width: 768px) {
    display: none;
  }
}
