@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css");

.App {
  font-family: "Pretendard", sans-serif;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
