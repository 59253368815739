.profile-title {
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    margin: 0rem 3.3rem;
  }
}
